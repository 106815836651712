//
//
//
//
//
//
//
//
//
//
//

import Animation from '@/components/Animation'
export default {
  components: {
    Animation,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    handleClicks($event, container) {
      // ensure we use the link, in case the click has been received by a subelement
      let { target } = $event
      while (target && target.tagName !== 'A') target = target.parentNode
      // handle only links that occur inside the component and do not reference external resources
      if (target && target.matches(container + ' a') && target.href) {
        // some sanity checks taken from vue-router:
        // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
        const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } =
          $event

        // don't handle with control keys
        if (metaKey || altKey || ctrlKey || shiftKey) return
        // don't handle when preventDefault called
        if (defaultPrevented) return
        // don't handle when mailto is in the link
        if (target.href.includes('mailto')) return
        // don't handle right clicks
        if (button !== undefined && button !== 0) return
        // don't handle if `target="_blank"`
        if (target && target.getAttribute) {
          const linkTarget = target.getAttribute('target')
          if (/\b_blank\b/i.test(linkTarget)) return
        }
        const url = new URL(target.href)
        const to = url.pathname + url.search

        // Don't handle external links
        if (url.host !== window.location.hostname) {
          $event.preventDefault()
          window.open(target.href, '_blank')
          return
        }
        // don't handle same page links/anchors
        if (window.location.pathname !== to && $event.preventDefault) {
          $event.preventDefault()
          this.$router.push(to)
        }
      }
    },
  },
}
