//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProjectTeaser from '@/components/project/ProjectTeaser'
export default {
  components: {
    ProjectTeaser,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  computed: {
    projects() {
      return this.data.projects.data.length > 0
        ? this.data.projects.data
        : this.$store.state.projects
    },
  },
}
