import { render, staticRenderFns } from "./ModuleProjects.vue?vue&type=template&id=0b05b1f5&"
import script from "./ModuleProjects.vue?vue&type=script&lang=js&"
export * from "./ModuleProjects.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProjectTeaser: require('/vercel/path0/components/project/ProjectTeaser.vue').default})
