var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.modules.length > 0)?_c('div',{staticClass:"grid grid-cols-1 gap-y-16 md:gap-y-28 pb-20",class:_vm.hero ? '' : 'pt-24 md:pt-56'},[_vm._l((_vm.modules),function(module,index){return [(module.__typename === 'ComponentModuleImages')?_c('div',{key:index},[_c('div',{staticClass:"image-container"},[_c('ModuleImages',{attrs:{"data":module,"imagesBefore":_vm.modules[index - 1] &&
              (_vm.modules[index - 1].__typename === 'ComponentModuleImages' ||
                _vm.modules[index - 1].__typename === 'ComponentModuleEmbed' ||
                _vm.modules[index - 1].__typename === 'ComponentModuleVideo'),"imagesAfter":_vm.modules[index + 1] &&
              (_vm.modules[index + 1].__typename === 'ComponentModuleImages' ||
                _vm.modules[index + 1].__typename === 'ComponentModuleEmbed' ||
                _vm.modules[index + 1].__typename === 'ComponentModuleVideo')}})],1)]):_vm._e(),_vm._v(" "),(module.__typename === 'ComponentModuleSlideshow')?_c('div',{key:index},[_c('div',[_c('ModuleSlideshow',{attrs:{"data":module,"imagesBefore":_vm.modules[index - 1] &&
              (_vm.modules[index - 1].__typename === 'ComponentModuleImages' ||
                _vm.modules[index - 1].__typename === 'ComponentModuleEmbed' ||
                _vm.modules[index - 1].__typename === 'ComponentModuleVideo'),"imagesAfter":_vm.modules[index + 1] &&
              (_vm.modules[index + 1].__typename === 'ComponentModuleImages' ||
                _vm.modules[index + 1].__typename === 'ComponentModuleEmbed' ||
                _vm.modules[index + 1].__typename === 'ComponentModuleVideo')}})],1)]):(module.__typename === 'ComponentModuleText')?_c('div',{key:index,staticClass:"container"},[_c('ModuleText',{key:index,attrs:{"data":module}})],1):(module.__typename === 'ComponentModuleProjects')?_c('div',{key:index,staticClass:"container"},[_c('ModuleProjects',{key:index,attrs:{"data":module}})],1):(module.__typename === 'ComponentModuleEmbed')?_c('div',{key:index,staticClass:"image-container"},[_c('ModuleEmbed',{attrs:{"data":module,"imagesBefore":_vm.modules[index - 1] &&
            (_vm.modules[index - 1].__typename === 'ComponentModuleImages' ||
              _vm.modules[index - 1].__typename === 'ComponentModuleEmbed' ||
              _vm.modules[index - 1].__typename === 'ComponentModuleVideo'),"imagesAfter":_vm.modules[index + 1] &&
            (_vm.modules[index + 1].__typename === 'ComponentModuleImages' ||
              _vm.modules[index + 1].__typename === 'ComponentModuleEmbed' ||
              _vm.modules[index + 1].__typename === 'ComponentModuleVideo')}})],1):(module.__typename === 'ComponentModuleVideo')?_c('div',{key:index,staticClass:"image-container"},[_c('ModuleVideo',{key:index,attrs:{"data":module,"imagesBefore":_vm.modules[index - 1] &&
            (_vm.modules[index - 1].__typename === 'ComponentModuleImages' ||
              _vm.modules[index - 1].__typename === 'ComponentModuleEmbed' ||
              _vm.modules[index - 1].__typename === 'ComponentModuleVideo'),"imagesAfter":_vm.modules[index + 1] &&
            (_vm.modules[index + 1].__typename === 'ComponentModuleImages' ||
              _vm.modules[index + 1].__typename === 'ComponentModuleEmbed' ||
              _vm.modules[index + 1].__typename === 'ComponentModuleVideo')}})],1):(module.__typename === 'ComponentModuleColors')?_c('div',{key:index,staticClass:"container"},[_c('ModuleColors',{attrs:{"data":module}})],1):_vm._e()]})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }