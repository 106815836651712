//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      intersectionOptions: {
        root: null,
        rootMargin: '0% 0% -50% 0%',
        threshold: 0,
      },
    }
  },
  methods: {
    onWaypoint({ going, direction }) {
      if (
        going === this.$waypointMap.GOING_IN &&
        direction === this.$waypointMap.DIRECTION_TOP
      ) {
        this.$colorMode.preference = this.data.invert ? 'dark' : 'light'
      }
      if (
        going === this.$waypointMap.GOING_OUT &&
        direction === this.$waypointMap.DIRECTION_BOTTOM
      ) {
        this.$colorMode.preference = this.data.invert ? 'light' : 'dark'
      }
    },
  },
}
