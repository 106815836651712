var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    { playing: _vm.playing },
    { autoplay: _vm.data.autoplay },
    { 'images-after': _vm.imagesAfter },
    { 'images-before': _vm.imagesBefore } ]},[_c('no-ssr',[(_vm.data)?_c('vue-plyr',{ref:"plyr",attrs:{"options":_vm.playerOptions}},[_c('div',{staticClass:"player plyr__video-embed",attrs:{"data-plyr-config":_vm.config},on:{"ready":_vm.playerReady,"play":_vm.play}},[_c('iframe',{attrs:{"src":_vm.playerSrc +
            '?transparent=0&loop=' +
            (_vm.data.autoplay ? 1 : 0) +
            '&byline=false&portrait=false&title=false&speed=true&transparent=0&gesture=media&controls=0&autoplay=' +
            (_vm.data.autoplay ? 1 : 0) +
            '&muted=' +
            (_vm.data.autoplay ? 1 : 0) +
            (_vm.data.autoplay ? '&background=1' : null),"allowfullscreen":"","allowtransparency":"","allow":"autoplay"}})])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }