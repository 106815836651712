//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return null
      },
    },
    imagesBefore: {
      type: Boolean,
      default: false,
    },
    imagesAfter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      playing: false,
      playerOptions: {
        vimeo: {
          background: 1,
          muted: this.data.autoplay ? 1 : 0,
          autopause: this.data.autoplay ? 0 : 1,
          loop: this.data.autoplay ? 1 : 0,
          autoplay: this.data.autoplay ? 1 : 0,
        },
        controls: { active: !this.data.autoplay },
        autoplay: this.data.autoplay,
        loop: { active: this.data.autoplay },
        muted: this.data.autoplay,
      },
    }
  },
  computed: {
    config() {
      return '{"controls": ["play", "play-large", "progress", "current-time", "duration", "mute", "volume"]}'
    },
    playerSrc() {
      if (this.data.link && this.data.link.includes('vimeo')) {
        let id = this.data.link.substring(
          this.data.link.indexOf('vimeo.com/') + 10
        )
        if (id.includes('/')) {
          const temp = id.split('/')
          id = temp[0]
        }
        const link = 'https://player.vimeo.com/video/' + id
        return link
      } else if (this.data.link && this.data.link.includes('youtube')) {
        return this.data.link.replace('watch?v=', 'embed/')
      } else {
        return ''
      }
    },
    provider() {
      if (this.data.link.includes('youtube')) {
        return 'youtube'
      } else if (this.data.link.includes('vimeo')) {
        return 'vimeo'
      } else {
        return ''
      }
    },
  },

  methods: {
    playerReady(event) {
      if (this.data.cover && this.data.cover.data) {
        event.detail.plyr.poster = this.data.cover.data.attributes.url
      }
    },
    play(event) {
      this.playing = true
    },
  },
}
