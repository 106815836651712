//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Media from '@/components/Media'
export default {
  components: {
    Media,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
    imagesBefore: {
      type: Boolean,
      default: false,
    },
    imagesAfter: {
      type: Boolean,
      default: false,
    },
  },
}
